import { Component, OnInit, inject } from "@angular/core";

import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ReportarProblemaService } from "./reportar-problema.service";
import { RequisicaoBug } from "../../core/model";
import { NzModalModule, NzModalRef } from "ng-zorro-antd/modal";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzSpinModule } from "ng-zorro-antd/spin";

@Component({
  selector: "app-reportar-problema",
  templateUrl: "./reportar-problema.component.html",
  styles: [``],
  imports: [
    FormsModule,
    ReactiveFormsModule,

    NzAlertModule,
    NzButtonModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzSpinModule,
    NzToolTipModule,
  ],
})
export class ReportarProblemaComponent implements OnInit {
  message = inject(NzMessageService);
  modal = inject(NzModalRef);

  imageFile: File | null = null;
  base64Image: string | undefined;
  salvando = false;
  isImageValid: boolean = true;

  form = this.fb.group(
    {
      title: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(75),
        ],
      ],
      description: [
        "",
        [
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(400),
        ],
      ],
    },
    { updateOn: "change" },
  );

  constructor(
    private fb: FormBuilder,
    private service: ReportarProblemaService,
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.modal.destroy();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.imageFile!);
      reader.onload = (e: any) => {
        this.base64Image = e.target.result;
      };
      this.isImageValid = true;
    }
  }

  submitForm() {
    if (this.form.valid) {
      const requisicao = new RequisicaoBug();
      requisicao.titulo = this.form.get("title")!.value as string;
      requisicao.descricao = this.form.get("description")!.value as string;
      if (this.base64Image) {
        requisicao.file = this.base64Image;
      }
      this.salvando = true;
      this.reportarProblema(requisicao);
    }
  }

  reportarProblema(requisicao: RequisicaoBug) {
    this.service.reportarProblema(requisicao).subscribe({
      next: (_resp) => {
        this.salvando = false;
        this.message.success("Problema foi reportado com sucesso.");
        this.closeModal();
      },
      error: (_error) => {
        this.message.error(
          "Falha ao reportar problema, tente novamente mais tarde.",
        );
        this.salvando = false;
      },
    });
  }
}
