/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, inject } from "@angular/core";
import { TemplateComponent } from "./template/template.component";
import { AuthService } from "./auth/auth.service";
import { WebSocketAuthService } from "./auth/websocket-auth.service";

@Component({
    selector: "app-root",
    template: `<app-template></app-template>`,
    imports: [TemplateComponent]
})
export class AppComponent {
  private readonly websocketAuthService = inject(WebSocketAuthService);

  ngOnInit() {
    this.websocketAuthService.initAuthListeners();
  }
}
